<template>
  <v-main class="no-print" app>
        <router-view></router-view>
  </v-main>
</template>

<script>
export default {};
</script>

<style scoped>
@media print {
  .no-print {
    padding: 0 !important;
  }
}
</style>